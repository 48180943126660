<template>
  <div>
    <b-modal
      id="add-equipment"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('SubmitNewUser')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          label="Equipment Name"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.personalInfoForm.firstName.$model"
            :state="validateState('firstName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Quantity"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.personalInfoForm.firstName.$model"
            :state="validateState('firstName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Value per Part"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            :state="validateState('firstName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Filiale"
          label-for="input-2"
        >
          <vue-select
            v-model="$v.personalInfoForm.gender.$model"
            required
            :options="['Bern', 'Luzern']"
            placeholder="Select Filiale"
            :state="validateState('gender')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <div>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmitAddUser"
          >
            {{ $t('Submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, alpha, minValue, numeric, alphaNum, email,
} from 'vuelidate/lib/validators';

export default {
  components: {
    // flatPickr,
  },
  mixins: [validationMixin],
  data() {
    return {
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee'],
      personalInfoForm: {
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        houseNumber: '',
        street: '',
        country: '',
        passport: '',
        aufenthaltsbewilligung: '',
        ahv: '',
        email: '',
        role: '',
      },
      workInfoForm: {
        startingDate: '',
        endingDate: '',
        workload: '',
        vacationDays: '',
        employeeType: '',
        role: '',
        salary: '',
      },
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
    };
  },
  validations: {
    personalInfoForm: {
      firstName: {
        required,
        minLength: minLength(3),
        alpha,
      },
      lastName: {
        required,
        minLength: minLength(3),
        alpha,
      },
      salutation: {
        required,
      },
      gender: {
        required,
      },
      city: {
        required,
        minLength: minLength(3),
        alpha,
      },
      // dateOfBirth: {
      //   required,
      //   dateFormat,
      // },
      // phoneNumber: {
      //   required,
      //   minLength: minLength(9),
      //   numeric,
      // },
      postalCode: {
        required,
        minLength: minLength(3),
        alphaNum,
      },
      doorNumber: {
        required,
        minLength: minValue(1),
        numeric,
      },
      street: {
        required,
        minLength: minLength(3),
      },
      country: {
        required,
        minLength: minLength(3),
        alpha,
      },
      email: {
        required,
        email,
      },
      role: {
        required,
      },
    },
  },
  computed: {
    // getSal() {
    //   return this.$store.state.login.salutation;
    // },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.personalInfoForm[name];
      return $dirty ? !$error : null;
    },
    onSubmitAddUser() {
      this.$v.personalInfoForm.$touch();
      if (this.$v.personalInfoForm.$anyError) {
        return;
      }
      this.$emit('click', this.personalInfoForm)
      this.$refs.modal.hide()
      this.onReset()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.personalInfoForm.firstName = '';
      this.personalInfoForm.lastName = '';
      this.personalInfoForm.salutation = '';
      this.personalInfoForm.gender = '';
      this.personalInfoForm.city = '';
      this.personalInfoForm.street = '';
      this.personalInfoForm.dateOfBirth = '';
      this.personalInfoForm.postalCode = '';
      this.personalInfoForm.doorNumber = '';
      this.personalInfoForm.country = '';
      this.personalInfoForm.phoneNumber = '';
      this.personalInfoForm.email = '';
      this.personalInfoForm.role = '';
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons{
  display: flex;
  align-items:flex-end;
}
</style>
