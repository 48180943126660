<template>
  <div>
    <div style="display: flex; flex-direction:row; justify-content:space-between">
      <button
        v-b-modal.add-equipment
        class="button"
        style="border-radius:6px; width:220px"
      >
        <b-icon-plus />
        Add Equipment
      </button>
      <div style="display:flex">
        <div
          class="search-toggle"
        >
          <p>{{ $t('SearchBy') }}</p>
          <div>
            <p
              class="active2"
            >
              Equipment Namee
            </p>
          </div>
        </div>
        <div
          class="table__search-input"
          style="margin-top: 0px !important; display: flex; align-items: center;"
        >
          <b-icon-search />
          <input
            v-model="searchTerm"
            style="font-size:15px;"
            placeholder="Equipment name"
            type="text"
          >
        </div>
      </div>
    </div>
    <div
      style="width: 100%; border-top: 1px solid gray; padding-top: 16px; margin-top: -3px;"
    >
      <table
        class="team_table"
        style="width: 100% !important; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%)"
      >
        <thead>
          <tr>
            <th
              style="padding: 10px; border-radius: 10px 0px 0px 0px;"
            >
              Equipment Name
            </th>
            <th
              style="padding: 10px;"
            >
              Quantity
            </th>
            <th
              style="padding: 10px;"
            >
              Value per Part
            </th>
            <th
              style="padding: 10px; border-radius: 0px 10px 0px 0px"
            >
              Filiale
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="name in getFiliale"
            :key="name"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
            :style="classNameCh === getFiliale.name ? 'background-color: #e8e8e8' : ''"
            @click="
              matProps(filiale);
            "
          >
            <td style="padding: 10px">
              {{ getFiliale.filialeName }}
            </td>
            <td style="padding: 10px">
              {{ getFiliale.address }}
            </td>
            <td style="padding: 10px">
              {{ getFiliale.todayRoutes }}
            </td>
            <td style="padding: 10px">
              {{ getFiliale.trucks }}
            </td>
            <td
              v-if="$route.path.includes('products')"
              v-b-modal.modal-edit-color
              style="padding: 10px; text-align: left; cursor: pointer"
              @click="edit(color)"
            >
              <b-icon-pencil />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddEquipmentModal />
    <!-- <EditDressColorModal
      :color="color"
      :dress-name="materialId.name"
      @updateColor="editColor"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import AddEquipmentModal from '@/components/equipments/modals/AddEquipmentModal.vue';
// import EditDressColorModal from '@/components/products/modals/EditDressColorModal.vue';

export default {
  components: {
    AddEquipmentModal,
    // EditDressColorModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['materialId', 'createOrder'],
  data() {
    return {
      initialId: '',
      status: true,
      classNameCh: '',
      searchTerm: '',
      rows: [],
      materialObject: {},
      editMat: {},
      color: {
        mainDressCategoryColorId: '',
        name: '',
      },
      getFiliale: {
        filialeName: 'Box',
        address: '5',
        todayRoutes: '150CHF',
        trucks: 'Value',

      },
    };
  },

  computed: {
    ...mapGetters(['getDressColors']),
  },
  watch: {
    // eslint-disable-next-line func-names
    materialId() {
      this.classNameCh = '';
    },
  },
  methods: {
    ...mapActions([

      'addColorToMainDressCategoryById',
      'editColorToMainDressCategoryById',
      'loadDressColorsById',
      'inventoryProductSizeFromColorId',
      'resetProductsByArticleNumber',
      'resetCustomOrders',

    ]),
    edit(item) {
      this.color.mainDressCategoryColorId = item.mainDressCategoryColorId
      this.color.name = item.colorName
      this.$bvModal.show('modal-edit-color');
    },
    async editColor(obj) {
      await this.editColorToMainDressCategoryById({
        object: obj,
        successCallback: () => {
          this.loadDressColorsById(this.materialId.id)
        },
      })
    },
    async addColor(obj) {
      obj.mainDressCategoryId = this.materialId.id;
      await this.addColorToMainDressCategoryById({
        object: obj,
        successCallback: () => {
          this.loadDressColorsById(this.materialId.id);
        },
      });
    },
    matProps(color) {
      this.resetProductsByArticleNumber();
      this.$emit('click', color);
      this.classNameCh = color.colorName;
      this.inventoryProductSizeFromColorId(color.mainDressCategoryColorId);
    },
  },
};
</script>

<style scoped lang="scss">
.color-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}
.button {
  margin-bottom: 15px !important;
  width:195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
 @media screen and (max-width: 1823px) {

.color-listing__wrapper2{
 width: 200px;
   button
   {
    width: 90%;
  }
  .team_table{
    width: 150px !important;
  }
 }
 }
@media screen and (max-width: 1400px) {

.color-listing__wrapper{
  margin-left: 50px;
  width: 150px !important;
  .team_table{
    width: 150px !important;
  }
}
.color-listing__wrapper2{
   margin-left: 10px;
  width: 200px !important;
  button{
width: 150px;
font-size: 13px;
height: 36px;
  }
  .team_table{
    width: 100% !important;
  }
}
}
@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
</style>
