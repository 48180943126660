<template>
  <div class="home">
    <section
      class="main no-flex"
      style="margin-top: 13px;"
    >

      <EquipmentsListing />
      <!-- <UsersAttendance
        :user="user"
      /> -->
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import EquipmentsListing from '@/components/equipments/EquipmentsListing.vue'
import { mapGetters } from 'vuex'
// import UsersAttendance from '@/components/properties/UsersAttedance.vue'


export default {
  name: 'Home',
  components: {
    EquipmentsListing,
    // UsersAttendance,
  },
  data() {
    return {
      user: '',
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
    handleUser(id) {
      this.user = id;
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1041px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
